



























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { searchModule } from '@/store/modules/search';
import { projectModule } from '@/store/modules/project';
import Project from '@/components/Project';
import { Dialog, OrderType } from '@/store/modules/project/types';

@Component({
    components: { Project }
})
export default class Email extends Vue {
    get districts(): number[] {
        return projectModule.filter.distrito;
    }

    set districts(val: number[]) {
        projectModule.setFilter({ distrito: val });
    }

    get dialogs() {
        return projectModule.dialogs;
    }

    get options() {
        return projectModule.order;
    }

    get projects() {
        return projectModule.projects;
    }

    get orderBy(): OrderType {
        return projectModule.filter.orderBy;
    }

    set orderBy(val: OrderType) {
        projectModule.setFilter({ orderBy: val });
    }

    get search() {
        return searchModule.searchSelected;
    }

    close(key: keyof Dialog) {
        projectModule.closeOrOpenDialog(key);
    }

    @Watch('search')
    load() {
        if (this.search) {
            projectModule.setFilter(this.search.filter);
        }
    }

    mounted() {
        if (this.search) {
            projectModule.setFilter(this.search.filter);
        }
    }

    handlePage() {
        projectModule.getProjectsByScroll(projectModule.filter.page + 1);
    }
}
